import { Box } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { useApi } from "hooks/useApi/useApiHooks";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomLink } from "styles/global.style";
import { numberFormat } from "utils/numberFormat";

export const useStudentsColumns = (): GridColumns<any> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: currency } = useApi(
    "/money-type",
    {},
  );

  return [
    // {
    //   field: t("TABLE.ID"),
    //   renderCell(params) {
    //     return get(params, "row.uuid");
    //   },
    //   flex: 0.5,
    // },
    {
      field: "contractNumber",
      headerName: t("TABLE.CONTRACT_NUMBER")!,
      sortable: true,
      renderCell(params) {
        return get(params, "row.contractNumber");
      },
      width: 180,
    },
    {
      field: "lastName",
      headerName: t("TABLE.FIO")!,
      sortable: true,
      renderCell(params) {
        return (
          <CustomLink
            onClick={() => navigate(`/students/info/${get(params, "row._id")}`)}
          >
            {get(params, "row.lastName", " ") +
              " " +
              get(params, "row.firstName", " ")}
          </CustomLink>
        );
      },
      width: 220,
    },
    {
      field: "balance",
      headerName: t("TABLE.BALANCE")!,
      sortable: true,
      renderCell(params) {
        return numberFormat(get(params, "row.balance", "")) + " "  + currency?.data?.name;
      },
      width: 180,
    },
    {
      field: t("TABLE.CLASS"),
      renderCell(params) {
        return (
          <span>
            {get(params, "row.class.grade", "") +
              "-" +
              get(params, "row.class.letter", " ")}
          </span>
        );
      },
      width: 60,
    },

    {
      field: t("TABLE.PHONE_NUMBER"),
      renderCell(params) {
        return get(params, "row.phoneNumber", " ");
      },
      width: 120,
    },
    {
      field: t("TABLE.HEAD_TEACHER"),
      renderCell(params) {
        return (
          <Box sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}>
            {get(params, "row.class.headTeacher", [])?.map(
              (teacher: Record<string, any>) => (
                <CustomLink
                  onClick={() =>
                    navigate(`/employees/info/${get(teacher, "_id", "")}`)
                  }
                >
                  {get(teacher, "firstName", "") +
                    " " +
                    get(teacher, "lastName", "")}
                </CustomLink>
              )
            )}
          </Box>
        );
      },
      width: 220,
    },
  ];
};
