import { FC, useEffect, useMemo } from "react";
import { IResponse, ISubject } from "../List";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import { useSearchParams } from "react-router-dom";

export interface ITableComponentProps {
  data: IResponse["data"];
}

export const TableComponent: FC<ITableComponentProps> = ({ data }) => {
  const { t } = useTranslation();
  const allParams = useAllQueryParams();
  const [__, setSearchParams] = useSearchParams();
  const tableData = useMemo(() => {
    const columns: any = [
      {
        field: "_number",
        headerName: "№",
        maxWidth: 60,
        minWidth: 60,
        align: "center",
        sortable: false,
        headerAlign: "center",
        disableColumnMenu: true,
      },
      {
        field: "fullName",
        headerName: t("TABLE.FIO"),
        width: 300,
      },
      {
        field: "class",
        sortable: false,
        disableColumnMenu: true,
        headerName: t("TABLE.CLASS"),
      },
    ];
    const rows: any = [];
    const subjects = new Set();
    data?.forEach((item, index) => {
      const row = {
        fullName: item.student.fullName,
        class: `${item.class.grade}-${item.class.letter}`,
        id: item._id,
        averageScore: item.averageScore,
        _number: index + 1,
      };
      item.subjects.forEach((s: ISubject) => {
        subjects.add(s.subjectName);
        (row as any)[s.subjectName] = s.mark;
      });
      rows.push(row);
    });
    Array.from(subjects).forEach((subject: any) =>
      columns.push({
        field: subject,
        sortable: false,
        disableColumnMenu: true,
        headerName: subject,
      })
    );
    columns.push({
      field: "averageScore",
      sortable: true,
      disableColumnMenu: true,
      headerName: t("TABLE.AVERAGE"),
    });

    return { rows, columns };
  }, [data]);

  const localeText = {
    columnMenuSortAsc: t("sorts.asc"),
    columnMenuSortDesc: t("sorts.desc"),
    columnMenuFilter: t("sorts.filter"),
    columnMenuUnsort: t("sorts.unsort"),
    columnMenuShowColumns: t("sorts.showColumns"),
    columnMenuHideColumn: t("sorts.hideColumns"),
    // columnMenuSearch: 'Search Columns',
    // columnsManagementNoColumns: 'No columns available', 
    // columnsManagementShowHideAllText: 'Toggle All', 
    // columnMenuReset: 'Reset Filters', 
    headerFilterOperatorContains: 'contain',
    headerFilterOperatorDoesNotContain: 'Does not contain',
    headerFilterOperatorEquals: 'Equals',
    headerFilterOperatorDoesNotEqual: 'Does not equal',
    headerFilterOperatorStartsWith: 'Starts with',
    headerFilterOperatorEndsWith: 'Ends with',
    headerFilterOperatorIs: 'Is',
    headerFilterOperatorNot: 'Is not',
    headerFilterOperatorAfter: 'Is after',
    headerFilterOperatorOnOrAfter: 'Is on or after',
    headerFilterOperatorBefore: 'Is before',
    headerFilterOperatorOnOrBefore: 'Is on or before',
    headerFilterOperatorIsEmpty: 'Is empty',
    headerFilterOperatorIsNotEmpty: 'Is not empty',
    headerFilterOperatorIsAnyOf: 'Is any of',
    // 'headerFilterOperator=': 'Equals',
    // 'headerFilterOperator!=': 'Not equals',
    // 'headerFilterOperator>': 'Greater than',
    // 'headerFilterOperator>=': 'Greater than or equal to',
    // 'headerFilterOperator<': 'Less than',
    // 'headerFilterOperator<=': 'Less than or equal to',
  };

  const savedTheme = localStorage.getItem("theme") || "light";

  return (
    <div className="h-[calc(100%-68px)]">
      <DataGrid
        hideFooter
        disableSelectionOnClick
        sortingMode="server"
        hideFooterPagination
        hideFooterSelectedRowCount
        rows={tableData.rows}
        columns={tableData.columns}
        showColumnRightBorder
        showCellRightBorder
        headerHeight={48}
        sx={{
          color: savedTheme === "light" ? "#000" : "#fff",
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
            color: savedTheme === "light" ? "#000" : "#fff",
          },
          "& .MuiDataGrid-menuIconButton": {
            color: savedTheme === "light" ? "#000" : "#fff",
          }
        }}
        sortModel={[
          {
            sort: allParams?.sortOrder === "1" ? "asc" : "desc",
            field: allParams?.sortBy,
          },
        ]}
        localeText={localeText}
        onSortModelChange={(model) => {
          if (model.length) {
            setSearchParams({
              ...allParams,
              sortBy: `${model[0].field}`,
              sortOrder: `${model[0].sort === "asc" ? 1 : -1}`,
            });
          } else {
            delete allParams.sortBy;
            delete allParams.sortOrder;
            setSearchParams({
              ...allParams,
            });
          }
        }}
      />
    </div>
  );
};
