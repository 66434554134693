import { IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Control, Controller, FieldPath } from "react-hook-form";
import { TRules } from "types/form.types";
import { TextInputStyled } from "./TextInput.style";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MoneyInput from "./MoneyInput/MoneyInput";
import { useTranslation } from "react-i18next";

const initialValidation = (
  type: React.InputHTMLAttributes<unknown>["type"]
) => ({
  required: { value: true, message: "general.required" },
  minLength:
    type === "password"
      ? {
          value: 6,
          message: "general.minLength6",
        }
      : type === "text"
      ? {
          value: 3,
          message: "general.minLength3",
        }
      : undefined,
});
export interface ITextField<FormNames extends Record<string, any>> {
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  rules?: TRules<FormNames>;
  label?: string;
  placeholder?: string | number;
  type?: React.InputHTMLAttributes<unknown>["type"];
  multiline?: boolean;
  disabled?: boolean;
  onCustomChange?: (val: string) => void;
  inputProps?: any;
  returnValueAsString?: boolean;
  maxRows?: number;
  rows?: number;
  className?: string;
  onClick?: (val: string) => void;
}

function TextInput<FormNames extends Record<string, any>>({
  label,
  control,
  name,
  placeholder = "",
  type = "text",
  multiline = false,
  rules,
  disabled = false,
  inputProps,
  returnValueAsString = false,
  onCustomChange,
  maxRows,
  rows,
  className,
  onClick
}: ITextField<FormNames>) {
  const [showPassword, setShowPassword] = useState(true);
  const { t } = useTranslation();

  if (type === "number") {
    return (
      <MoneyInput
        control={control}
        name={name}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        inputProps={inputProps}
        onCustomChange={onCustomChange}
        onClick={(e:any) => onClick?.(e.target.value)}
        returnValueAsString={returnValueAsString}
        rules={{
          ...initialValidation("number"),
          ...rules,
        }}
      />
    );
  }
  const textInputRules = {
    ...initialValidation(type),
    ...rules,
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={textInputRules}
      // @ts-ignore
      // defaultValue={type === "text" ? "" : undefined}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => {
        return (
          <TextInputStyled disabled={disabled}>
            {label && (
              <label htmlFor={name}>
                {label}
                <span>
                  {label && textInputRules?.required ? (
                    <span className="text-error mt-1">*</span>
                  ) : null}
                </span>
              </label>
            )}
            <TextField
              className={className}
              sx={{
                "& fieldset": { border: "none" },
              }}
              type={
                type === "password"
                  ? showPassword
                    ? "password"
                    : "text"
                  : type
              }
              onChange={(e) => {
                onChange(e);
                onCustomChange?.(e.target?.value);
              }}
              onBlur={onBlur}
              onClick={(e:any) => onClick?.(e.target.value)}
              disabled={disabled}
              autoComplete="off"
              value={value}
              name={name}
              // inputMode={type === "number" ? { inputMode: "numeric" } : undefined}
              // inputProps={
              // type === "number" ? { inputMode: "numeric" } : undefined
              // }
              placeholder={String(placeholder)}
              ref={ref}
              id={name}
              inputProps={inputProps}
              InputProps={{
                endAdornment: type === "password" && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                      sx={{
                        width: "40px !important",
                        height: "40px !important",
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              multiline={multiline}
              rows={rows}
              maxRows={maxRows}
            />
            {error && (
              <>
                <h6 className="text-error">
                  {label} {t(error.message!)}
                </h6>
              </>
            )}
          </TextInputStyled>
        );
      }}
    />
  );
}

export default TextInput;
