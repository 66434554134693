import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { SelectBtnIcon, SettingIcon, TopToBottomIcon } from "assets/svgs";
import {
  AutoCompleteFilter,
  CommonButton,
  DateYearFilter,
  ExportButton,
  RangeDatePicker,
  Table,
  TextInput,
  TooltipBox,
} from "components";
import dayjs from "dayjs";
import { useApi, useApiMutation } from "hooks/useApi/useApiHooks";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrderHeaderStyled, SecondRowShow } from "styles/global.style";
import { ButtonStyle, SettingModal } from "./style";
import { useSeasonalAppropriationColumn } from "./seasonal.column";
import { TYPES } from "pages/monthly-assessment/common/type";
import { class_grade } from "constants/pageConstants";
import { useSearchParams } from "react-router-dom";

interface IForm {
  formativeAssessmentPercent: number;
  summativeAssessmentPercent: number;
}
const SeasonalAppropriation = () => {
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [openSettingModal, setOpenSettingModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const [__, setParams] = useSearchParams();
  const allParams = useAllQueryParams();
  const [sortByClass, setSortByClass] = useState<any>(true);
  const [sortByClassOrder, setSortByClassOrder] = useState<any>(1);
  const defaultYear = dayjs().get("year");

  const { control, handleSubmit, reset, watch, setValue } = useForm<IForm>();

  const handleSortByClass = () => {
    if (sortByClass === true && sortByClassOrder === 1) {
      setSortByClass(true)
      setSortByClassOrder(-1)
      setParams({
        sortByClass: sortByClass,
        sortByClassOrder: sortByClassOrder
      })
    } else if (sortByClass === true && sortByClassOrder === -1) {
      setSortByClass(true)
      setSortByClassOrder(1)
      setParams({
        sortByClass: sortByClass,
        sortByClassOrder: sortByClassOrder
      })
    } else {
      delete allParams.sortBy;
      delete allParams.sortOrder;
      setParams({
        ...allParams
      })
    }
  }
  const columns = useSeasonalAppropriationColumn({ handleSortByClass });

  const handleClose = () => {
    setOpenSettingModal(false);
  };

  const { data, status } = useApi(
    "settings",
    {},
    { enabled: openSettingModal }
  );
  const { mutate, status: submitStatus } = useApiMutation("settings", "post");

  const { data: quarters } = useApi("quarter");

  const submitSetting = (data: any) => {
    mutate(data);
  };

  const parsedData = allParams?.studentId
    ? JSON.parse(allParams.studentId)
    : {};
  const studentId = parsedData?._id;

  const classIdsArray =
    JSON.parse(allParams?.classIds || "[]")?.map((clas: any) => clas?._id) ||
    [];
  const subjectIdsArray =
    JSON.parse(allParams?.subjectIds || "[]")?.map((sub: any) => sub?._id) ||
    [];

  const queryParts = [
    `year=${allParams?.year || defaultYear}`,
    allParams?.grade ? `grade=${allParams.grade}` : "",
    allParams?.month ? `month=${allParams.month}` : "",
    studentId ? `studentId=${studentId}` : "",
    classIdsArray.length > 0 ? `classIds=${JSON.stringify(classIdsArray)}` : "",
    subjectIdsArray.length > 0
      ? `subjectIds=${JSON.stringify(subjectIdsArray)}`
      : "",
    allParams?.quarterId ? `quarterId=${allParams.quarterId}` : "",
    allParams?.sortByClass ? `sortByClass=${allParams.sortByClass}` : "",
    allParams?.sortByClassOrder ? `sortByClassOrder=${allParams.sortByClassOrder}` : "",
    allParams?.sortBy ? `sortBy=${allParams.sortBy}` : "",
    allParams?.sortOrder ? `sortOrder=${allParams.sortOrder}` : "",
  ];

  const exportUrl = `/analitics/students/quarter/export?${queryParts
    .filter(Boolean)
    .join("&")}`;

  useEffect(() => {
    if (status === "success") {
      reset({
        formativeAssessmentPercent: data?.data?.formativeAssessmentPercent,
        summativeAssessmentPercent: data?.data?.summativeAssessmentPercent,
      });
    }
  }, [status]);

  useEffect(() => {
    if (submitStatus === "success") {
      setOpenSettingModal(false);
    }
  }, [submitStatus]);

  const renderHeader = (
    <OrderHeaderStyled>
      <TooltipBox title={t("general.quarterly_assessment_setting")}>
        <ButtonStyle onClick={() => setOpenSettingModal(true)}>
          <SettingIcon />
        </ButtonStyle>
      </TooltipBox>
      <ExportButton url={exportUrl} />
      <Button className="filter_btn" onClick={() => setIsTrue(!isTrue)}>
        <div>
          <SelectBtnIcon />
          <span>{t("LABELS.FILTER.FILTER_TITLE")}</span>
        </div>
        <div className={isTrue ? "icon active" : "icon"}>
          <TopToBottomIcon />
        </div>
      </Button>
    </OrderHeaderStyled>
  );

  const renderSecondRow = (
    <SecondRowShow in={isTrue}>
      <Grid
        container
        spacing={1}
        mb={"12px"}
        display={"flex"}
        justifyContent={"flex-end"}
        paddingRight={"8px"}
      >
        {/* <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.TYPE")!}
            filterName="type"
            options={TYPES.map((value) => ({
              _id: value._id,
              name: t(`general.${value.name}`),
            }))}
          />
        </Grid> */}
        <Grid item md={3} xs={3}>
          <DateYearFilter defaultValue={dayjs()} filterName="year" />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.QUARTER")!}
            filterName="quarterId"
            options={quarters?.data?.map((quarter: any, index: number) => ({
              ...quarter,
              name: `${index + 1} - ${t("general.quarter")}`,
            }))}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <DateYearFilter filterName="month" type="month" clearable placeholder={t('general.select_month')} />
        </Grid>

        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.CLASS")!}
            filterName="classIds"
            optionsUrl="/class/pagin"
            multiple
            getOptionLabel={(option) => option?.grade + " " + option?.letter}
          />
        </Grid>

        <Grid item xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.CLASS_GRADE")!}
            filterName="grade"
            options={class_grade.map((value) => ({
              ...value,
              _id: value._id,
              name: t(value.name),
            }))}
          // multiple
          />
        </Grid>

        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.SUBJECT")!}
            filterName="subjectIds"
            optionsUrl="subjects/pagin"
            multiple
          />
        </Grid>

        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            filterName="studentId"
            placeholder={t("LABELS.STUDENT")!}
            optionsUrl="/students/pagin"
            returnOnlyId={false}
            getOptionLabel={(option) =>
              option?.firstName + " " + option?.lastName
            }
          />
        </Grid>
      </Grid>
    </SecondRowShow>
  );

  return (
    <div>
      <Table<any>
        headerChildren={renderHeader}
        headerChildrenSecondRow={renderSecondRow}
        dataUrl="/analitics/students/quarter"
        columns={columns}
        title={t("general.seasonal_appropriation")}
        exQueryParams={{ year: allParams.year || defaultYear }}
        rowBackgroundColorKey="color"
        processingParams={(params) => ({
          ...params,
          studentId: JSON.parse(params?.studentId || "[]")?._id,
          classIds: JSON.stringify(
            JSON.parse(params?.classIds || "[]")?.map((clas: any) => clas?._id)
          ),
          subjectIds: JSON.stringify(
            JSON.parse(params?.subjectIds || "[]")?.map((sub: any) => sub?._id)
          ),
        })}
      />

      <Modal open={openSettingModal} onClose={handleClose}>
        <SettingModal>
          <form onSubmit={handleSubmit(submitSetting)}>
            <Box mb={3}>
              <Typography className="title">
                {t("general.quarterly_assessment_setting")}
              </Typography>
            </Box>
            <Grid container spacing={"16px"}>
              <Grid item xs={12}>
                <TextInput
                  control={control}
                  name="formativeAssessmentPercent"
                  type="number"
                  inputProps={{ maxLength: 2 }}
                  onCustomChange={(value) => {
                    if (+value < 100) {
                      setValue("summativeAssessmentPercent", 100 - +value);
                    }
                  }}
                  label={t("general.formativeAssessmentPercent")!}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  control={control}
                  inputProps={{ maxLength: 2 }}
                  name="summativeAssessmentPercent"
                  type="number"
                  onCustomChange={(value) => {
                    if (+value < 100 && +value > 0) {
                      setValue("formativeAssessmentPercent", 100 - +value);
                    }
                  }}
                  label={t("general.summativeAssessmentPercent")!}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                marginTop: "16px",
                justifyContent: "flex-end",
              }}
            >
              <CommonButton
                className="grey"
                onClick={handleClose}
                title={t("general.back")!}
              />
              <CommonButton
                className="main"
                type="submit"
                title={t("general.save")!}
              />
            </Box>
          </form>
        </SettingModal>
      </Modal>
    </div>
  );
};

export default SeasonalAppropriation;
