import {
  AnaliticIcon,
  ChatIcon,
  DashboardReportIcon1,
  DashboardReportIcon2,
  DashboardReportIcon3,
  DashboardReportIcon4,
  FinanceIcon,
  JournalIcon,
  ManageIcon,
  MonthlyAssessment,
  SchoolIcon,
  SeasionalAssignment,
  SettingIcon,
  TaskIcon,
  TimeTableIcon,
} from "assets/svgs";
import { ISidebarRoute } from "../sidebar.types";
import { SeasionalAssestment } from "pages/SeasionalAssesment";
import SeasonalAppropriation from "pages/analitics/seasonal-appropriation/SeasonalAppropriation";

export const sidebarRoutes: ISidebarRoute[] = [
  // ? dashboard

  {
    icon: <DashboardReportIcon1 />,
    title: "Dashboard",
    path: "/dashboard",
    translate: "DASHBOARD",
    role: "_id",
  },

  // ? schedule

  {
    icon: <TimeTableIcon />,
    title: "Dars jadval",
    path: "/schedule",
    translate: "TIME_TABLE",
    role: "getLessons",
  },

  // ? task

  {
    icon: <TaskIcon />,
    title: "Topshiriqlar",
    path: "/task",
    translate: "TASK",
    role: "_id", //! must change
  },

  // ? school

  {
    icon: <SchoolIcon />,
    title: "O'quv bo'limi",
    translate: "STUDY_DEPARTMENT",
    items: [
      {
        title: "Sinf",
        path: "/class",
        translate: "BARN_ALL.CLASS",
        role: "getClasses",
      },
      {
        title: "Group",
        path: "/group",
        translate: "BARN_ALL.GROUPS",
        role: "getClasses", // must change
      },
      {
        title: "Fanlar",
        path: "/subjects",
        translate: "BARN_ALL.SCIENCE",
        role: "getSubjects",
      },
      {
        title: "Xonalar",
        path: "/rooms",
        translate: "BARN_ALL.ROOMS",
        role: "getRooms",
      },
      {
        title: "O'quvchilar",
        path: "/students",
        translate: "BARN_ALL.STUDENTS",
        role: "getStudents",
      },
      {
        title: "Arxiv o'quvchilar",
        path: "/archive-students",
        translate: "BARN_ALL.ARCHIVE_STUDENTS",
        role: "getStudents",
      },
      {
        title: "Ota onalar",
        path: "/parents",
        translate: "BARN_ALL.PARENTS",
        role: "getParents",
      },
    ],
  },

  // ? journal

  {
    icon: <JournalIcon />,
    title: "Jurnal",
    path: "/journal",
    translate: "JOURNAL",
    role: "getJournal",
  },
  {
    icon: <ChatIcon />,
    title: "Chat",
    path: "/chat",
    translate: "CHAT",
    role: "_id", //! must change
  },

  // ? monthly-assessment

  {
    icon: <MonthlyAssessment />,
    title: "Oylik baholash",
    path: "/monthly-assessment",
    translate: "MONTHLY_ASSESSMENT",
    role: "getSeasonalMarks",
  },

  {
    icon: (
      <div className="h-6 w-6">
        <SeasionalAssignment />
      </div>
    ),
    title: "Mavsumiy baholash (fanlar kesimida)",
    path: "/seasional-assessment",
    translate: "SEASIONAL_ASSESSMENT_BY_SUBJECTS",
    role: "getSeasonalMarks",
  },

  // ? analitics

  {
    icon: <AnaliticIcon />,
    title: "Analitika",
    translate: "ANALITICS",
    items: [
      {
        title: "Analitika",
        path: "/analitics/appropriation",
        translate: "ANALITICS_ALL.APPROPRIATION",
        role: "getMarksAvarageAnalitics",
      },
      {
        title: "O'zlashtirish ko'rsatkichlari",
        path: "/analitics/seasonal-appropriation",
        translate: "ANALITICS_ALL.SEASONAL_APPROPRIATION",
        role: "getQuarterMarkAnalitics",
      },
      {
        title: "Sinflar reytingi",
        path: "/analitics/class-rating",
        translate: "ANALITICS_ALL.CLASS_RATING",
        role: "getClassSeasonalMarkAnalitics",
      },
      {
        title: "Davomat analitikasi",
        path: "/analitics/attendance-analytics/",
        translate: "ANALITICS_ALL.ATTENDANCE_ANALYTICS",
        // role: "getClassSeasonalMarkAnalitics",
      },
      {
        title: "O'qituvchi ish xisoboti",
        path: "analitics/teacher-work-reports",
        translate: "ANALITICS_ALL.TEACHER_WORK_REPORTS",
        role: "getJournalActivity",
      },
      {
        title: "Mavsumiy baholash xisoboti",
        path: "analitics/teacher-seasional-assessment",
        translate: "ANALITICS_ALL.MONTHLY_ASSESSMENT_REPORTS",
        // role: "getTeacherWorkReport",
      },
    ],
  },

  // ? finance

  {
    icon: <FinanceIcon />,
    title: "Moliya",
    translate: "FINANCE",
    items: [
      {
        title: "Moliya",
        path: "/cash",
        translate: "FINANCE_ALL.CASH",
        role: "cashboxGet",
      },
      {
        title: "Oylik chiqarish",
        path: "/salary",
        translate: "FINANCE_ALL.MONTHLY_SALARY",
        role: "getEmployeeSalaryData",
      },
      {
        title: "Moliya hisobotlari",
        path: "/financial-reports",
        translate: "FINANCE_ALL.FINANCIAL_REPORTS",
        role: "_id",
      },
    ],
  },
  // ? managment

  {
    icon: <ManageIcon />,
    title: "Boshqaruv",
    translate: "MANAGMENT",
    items: [
      {
        title: "Filiallar",
        path: "/branch",
        role: "getBranch",
        translate: "SETTINGS_ALL.Filiallar",
      },
      {
        title: "Ruxsatlar",
        path: "/roles",
        role: "getRoles",
        translate: "SETTINGS_ALL.ALLOWED",
      },
      {
        title: "Xodimlar",
        path: "/employees",
        translate: "SETTINGS_ALL.EMPLOYEE",
        role: "getEmployees",
      },
    ],
  },

  // ? settings

  {
    icon: <SettingIcon />,
    title: "Sozlamalar",
    translate: "SETTINGS",
    items: [
      {
        title: "Moliya",
        path: "/finance-settings",
        role: "FinanceSettings",
        translate: "SETTINGS_ALL.FINANCE_SETTINGS",
      },
      {
        title: "Integrations",
        path: "/integrations-settings",
        role: "integrations",
        translate: "SETTINGS_ALL.INTEGRATIONS",
      },
      {
        title: "Umumiy sozlamalar",
        path: "/general-settings",
        role: "generalSettings",
        translate: "SETTINGS_ALL.GENERAL_SETTINGS",
      },
    ],
  },
];

export const sidebarTeacherRoutes: ISidebarRoute[] = [
  // ? time-table

  {
    icon: <TimeTableIcon />,
    title: "Dars jadval",
    path: "/schedule",
    translate: "TIME_TABLE",
    role: "getLessons",
  },

  // ? journal

  {
    icon: <JournalIcon />,
    title: "Jurnal",
    path: "/journal",
    translate: "JOURNAL",
    role: "getClasses",
  },

  // ? chat

  {
    icon: <ChatIcon />,
    title: "Chat",
    path: "/chat",
    translate: "CHAT",
    role: "_id", //! must change
  },

  // ? monthly-assessment

  {
    icon: <MonthlyAssessment />,
    title: "Oylik baholash",
    path: "/monthly-assessment",
    translate: "MONTHLY_ASSESSMENT",
    role: "getSeasonalMarks",
  },
  {
    icon: <AnaliticIcon />,
    title: "O'zlashtirish ko'rsatkichlari",
    path: "/seasonal-appropriation",
    translate: "ANALITICS_ALL.SEASONAL_APPROPRIATION",
    role: "getQuarterMarkAnalitics",
  },
];
