import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MainHeader } from "styles/global.style";
import { Filters } from "./components/Filters";
import { List } from "./components/List";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import ExportButton from "components/elements/exportButtonDelay/ExportButton";
import styled from "styled-components";

const BgColorDiv = styled.div`
  background-color: ${({ theme }) => theme.background.body};
  height: 91%;
`;

export interface ISeasionalAssestmentProps { }

export const SeasionalAssestment: FC<ISeasionalAssestmentProps> = ({ }) => {
  const methods = useForm();
  const { t } = useTranslation();
  const [params, setParams] = useState<string | null>(null);
  const [__, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({ limit: "10", page: "1" });
  }, []);

  function handleParams(data: any) {
    const searchParams = new URLSearchParams();
    searchParams.append("classIds", JSON.stringify(data?.classIds));
    searchParams.append("subjectIds", JSON.stringify(data?.subjectIds));
    searchParams.append("type", data?.type);
    searchParams.append(
      "month",
      (dayjs(data?.month_and_year).get("month") + 1).toString()
    );
    searchParams.append(
      "year",
      dayjs(data?.month_and_year).get("year").toString()
    );
    if (data?.quarterId) {
      searchParams.append("quarter", data?.quarterId);
      searchParams.delete("month");
    }

    Array.from(__).forEach(([key, value]) => searchParams.append(key, value));

    setParams(searchParams.toString());
  }
  const fields = methods.watch();
  useEffect(() => {
    if (
      fields?.type &&
      fields?.month_and_year &&
      fields?.classIds?.length &&
      fields?.subjectIds?.length
    ) {
      handleParams(fields);
    } else {
      setParams(null);
    }
  }, [fields]);

  console.log(fields);

  const exportUrl = `/seasonal-mark/bysubjects/export?${params}`;

  return (
    <FormProvider {...methods}>
      <MainHeader>{t("general.seasional_assestment")}
        <ExportButton url={exportUrl} noClick={!!(fields?.classIds?.length > 0 &&
          fields?.month_and_year &&
          fields?.subjectIds?.length > 0 &&
          fields?.type)} />
      </MainHeader>
      <BgColorDiv>
      <div className={`h-full w-full p-4`}>
        <div className="h-full w-full rounded-lg">
          <Filters />
          <List params={params} />
        </div>
      </div>
      </BgColorDiv>
    </FormProvider>
  );
};
