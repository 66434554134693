export const class_grade = [
  {
    _id: "0",
    name: "class_grade.grade0",
  },
  {
    _id: "1",
    name: "class_grade.grade1",
  },
  {
    _id: "2",
    name: "class_grade.grade2",
  },
  {
    _id: "3",
    name: "class_grade.grade3",
  },
  {
    _id: "4",
    name: "class_grade.grade4",
  },
  {
    _id: "5",
    name: "class_grade.grade5",
  },
  {
    _id: "6",
    name: "class_grade.grade6",
  },
  {
    _id: "7",
    name: "class_grade.grade7",
  },
  {
    _id: "8",
    name: "class_grade.grade8",
  },
  {
    _id: "9",
    name: "class_grade.grade9",
  },
  {
    _id: "10",
    name: "class_grade.grade10",
  },
  {
    _id: "11",
    name: "class_grade.grade11",
  },
];


export const class_grade_num = [
  {
    _id: 0,
    name: "class_grade.grade0",
  },
  {
    _id: 1,
    name: "class_grade.grade1",
  },
  {
    _id: 2,
    name: "class_grade.grade2",
  },
  {
    _id: 3,
    name: "class_grade.grade3",
  },
  {
    _id: 4,
    name: "class_grade.grade4",
  },
  {
    _id: 5,
    name: "class_grade.grade5",
  },
  {
    _id: 6,
    name: "class_grade.grade6",
  },
  {
    _id: 7,
    name: "class_grade.grade7",
  },
  {
    _id: 8,
    name: "class_grade.grade8",
  },
  {
    _id: 9,
    name: "class_grade.grade9",
  },
  {
    _id: 10,
    name: "class_grade.grade10",
  },
  {
    _id: 11,
    name: "class_grade.grade11",
  },
];