import { Box, IconButton } from "@mui/material";
import styled from "styled-components";

export const ButtonStyle = styled(IconButton)`
  width: 40px;
  height: 40px;
  border-radius: 5px;

  svg {
    path {
      stroke: ${({ theme }) => theme.primary.dark};
    }
  }
`;

export const SettingModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.background.main};
  width: 440px;

  .title {
    font-size: 22px;
    color: ${({ theme }) => theme.primary.dark};
  }
`;
