import { Control, Controller, FieldPath } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInputComponent from "react-phone-input-2";
import { useState } from "react";
import { TRules } from "types/form.types";
import {
  PhoneNumberCountryStyled,
  PhoneNumberlabelStyled,
} from "./PhoneInput.style";

export const phoneNumberPattern = /^\+\d{1,4}\d{6,14}$/;

export interface IPhoneInputProps<FormValues extends Record<string, any>> {
  control: Control<FormValues>;
  rules?: TRules<FormValues>;
  name: FieldPath<FormValues>;
  label?: string;
  disabled?: boolean;
  autofocus?: boolean;
}

function PhoneInput<FormValues extends Record<string, any>>({
  name,
  label = "",
  rules = {
    required: { value: true, message: "Majburiy maydon" },
    pattern: {
      value: phoneNumberPattern,
      message: "Telefon raqamni noto'g'ri kiritdingiz!",
    },
  },
  control,
  disabled = false,
  autofocus = false,
}: IPhoneInputProps<FormValues>) {
  const [countryCode, setCountryCode] = useState<string>("uz");

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <>
            {!!label && (
              <PhoneNumberlabelStyled>
                <label>
                  {label || name}
                  {rules.required ? (
                    <span className="text-error mt-1">*</span>
                  ) : null}
                </label>
              </PhoneNumberlabelStyled>
            )}
            <PhoneNumberCountryStyled
              country={countryCode}
              enableAreaCodes={true}
              placeholder="+998-__-__-_"
              value={field.value}
              onBlur={field.onBlur}
              disabled={disabled}
              disableDropdown={false}
              inputProps={{
                ref: field.ref,
                autoFocus: autofocus,
              }}
              onChange={(val: string, data: any) => {
                field.onChange("+" + val);
                setCountryCode(data.countryCode);
              }}
            />
            {fieldState.error && (
              <h6 className="text-error mt-1">{fieldState.error.message}</h6>
            )}
          </>
        );
      }}
    />
  );
}

export default PhoneInput;
