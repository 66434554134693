import { Box } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CustomLink } from "styles/global.style";
import { numberFormat } from "utils/numberFormat";

import SwapVertIcon from '@mui/icons-material/SwapVert';

interface IAppropriation {
  name: string;
  student: {
    fullName: string;
    phoneNumber: string;
    _id: string;
  };
  subject: {
    name: string;
    _id: string;
  };
  class: {
    grade: number;
    letter: string;
    _id: string;
  };
}
export const useSeasonalAppropriationColumn =
  ({ handleSortByClass }: any): GridColumns<IAppropriation> => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return [
      {
        field: t("general.student"),
        renderCell({ row }) {
          return (
            <CustomLink
              onClick={() =>
                navigate(`/students/info/${get(row, "student._id", "")}`)
              }
            >
              {get(row, "student.fullName", "")}
            </CustomLink>
          );
        },
      },
      {
        field: "class",
        headerName: t("general.class"), // Oddiy string
        renderHeader: () => (
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            {t("general.class")}
            <span
              style={{ marginLeft: '5px', color: 'blue' }}
              onClick={handleSortByClass}
            >
              <SwapVertIcon className="text-[#249EB1]" />
            </span>
          </div>
          //    <Box>
          //    <SortIcon style={{ cursor: 'pointer' }} onClick={allParams.sortByClass ? () => allParams.setSortModel([{ field: 'name', sort: params.sort === 'asc' ? 'desc' : 'asc' }]) : null} />
          //    {param.sort === 'asc' ? <ArrowUpwardIcon /> : params.sort === 'desc' ? <ArrowDownwardIcon /> : null}
          //  </Box>
        ),
        sortable: false,
        renderCell({ row }) {
          return (
            get(row, "class.grade", "") + "-" + get(row, "class.letter", "")
          );
        },
      },
      {
        field: t("general.subject"),
        renderCell({ row }) {
          return get(row, "subject.name", "");
        },
      },
      {
        field: "formativeAssessment",
        headerName: t("general.formativeAssessmentPercent")!,
        sortable: true,
        renderCell({ row }) {
          return get(row, "formativeAssessment", "");
        },
      },
      {
        field: "summativeAssessment",
        headerName: t("general.summativeAssessmentPercent")!,
        sortable: true,
        renderCell({ row }) {
          return get(row, "summativeAssessment", "");
        },
      },
      {
        field: "totalMark",
        headerName: t("general.totalMark")!,
        sortable: true,
        renderCell({ row }) {
          return <Box>{numberFormat(get(row, "totalMark", "0"))}</Box>;
        },
      },
      {
        field: "score",
        headerName: t("general.score")!,
        // sortable: true,
        renderCell({ row }) {
          return <Box>{numberFormat(get(row, "score", "0"))}</Box>;
        },
      },
    ];
  };
