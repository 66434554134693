import { AutoCompleteFilter, ExportButton, RangeDatePicker, Table } from "components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useRoomColumns } from "./list.columns";
import { ITeacherWorkReport } from "../../types";
import { useState } from "react";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";

export const List = () => {
  const thisMonthStart = dayjs().startOf("day").toISOString();
  const thisMonthEnd = dayjs().endOf("day").toISOString();
  const [dateRange, setDateRange] = useState({
    fromDate: thisMonthStart,
    toDate: thisMonthEnd,
  });

  const columns = useRoomColumns();
  const { t } = useTranslation();
  const allParams = useAllQueryParams();

  const teacherIdsArray =
    JSON.parse(allParams?.teacherIds || "[]")?.map((teach: any) => teach?._id) ||
    [];

  const exportUrl = `/analitics/journal-activity/export?fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}&${teacherIdsArray.length > 0 ? `teacherIds=${JSON.stringify(teacherIdsArray)}` : ""}`;
  
  const renderHeader = (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <RangeDatePicker
        defaultValues={[dayjs(thisMonthStart), dayjs(thisMonthEnd)]}
        onchange={(val: any) => {
          if (val) {
            setDateRange({
              fromDate: dayjs(val[0]).startOf("day").toISOString(),
              toDate: dayjs(val[1]).endOf("day").toISOString(),
            });
          }
        }}
      />
      <ExportButton url={exportUrl} />
      <AutoCompleteFilter
        placeholder={t("general.teacher")!}
        filterName="teacherIds"
        optionsUrl="employees/pagin"
        multiple
        exQueryParams={{
          limit: 200,
          page: 1,
          type: "teacher",
        }}
      />
    </div>
  );

  return (
    <div>
      <Table<ITeacherWorkReport>
        dataUrl="/analitics/journal-activity"
        columns={columns}
        title={t("general.teacher-work-report")}
        headerChildren={renderHeader}
        exQueryParams={{ ...dateRange }}
        processingParams={(params) => {
          return {
            ...params,
            teacherIds: JSON.stringify(
              JSON.parse(params?.teacherIds || "[]")?.map(
                (value: Record<string, any>) => value?._id
              )
            ),
          };
        }}
      />
    </div>
  );
};
