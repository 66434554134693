// import TeacherSeasionalAssessment from "pages/analitics/teacherSeasionalAssessment";
import SeasonalAppropriation from "pages/analitics/seasonal-appropriation/SeasonalAppropriation";
import TeacherWorkReport from "pages/analitics/teacherWorkReport";
import ArchiveStudents from "pages/school/archiveStudents/list/container/Students";
import { Parents } from "pages/school/parents/container/Parents";
import { SeasionalAssestment } from "pages/SeasionalAssesment";
import { Integrations } from "pages/settings/integrations/container/Integrations";
import { SmsTemplates } from "pages/settings/sms-templates";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { IRoles_ } from "services/useRoleManager";

const Class = lazy(() => import("pages/school/class"));
const Group = lazy(() => import("pages/school/group"));
const Subjects = lazy(() => import("pages/school/subjects"));
const Rooms = lazy(() => import("pages/school/rooms"));
const Students = lazy(() => import("pages/school/students"));

const BLACK_PAGE = lazy(() => import("pages/black_page"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Schedule = lazy(() => import("pages/schedule"));
const Task = lazy(() => import("pages/task"));
const Journal = lazy(() => import("pages/journal"));
const Chat = lazy(() => import("pages/chat"));

const MonthlyAssessment = lazy(() => import("pages/monthly-assessment"));
const Cash = lazy(() => import("pages/finance/cash/ui"));
const Salary = lazy(() => import("pages/finance/salary/list"));
const FinancialReports = lazy(() => import("pages/finance/reports"));
const MonthlySalary = lazy(() => import("pages/finance/salary/action"));

const Appropriation = lazy(() => import("pages/analitics"));
const TeacherSeasionalAssessment = lazy(() => import("pages/analitics/teacherSeasionalAssessment"));

const LessonTime = lazy(() => import("pages/settings/lessonTime"));
const Receipt = lazy(() => import("pages/settings/receipt"));
const Buildings = lazy(() => import("pages/settings/building"));
const Branch = lazy(() => import("pages/managment/branch"));
const Roles = lazy(() => import("pages/managment/roles"));
const Employees = lazy(() => import("pages/managment/employees"));
const Quarter = lazy(() => import("pages/settings/quarter"));
const EvalutionSystem = lazy(() => import("pages/settings/evalution-system"));
const TransactionType = lazy(() => import("pages/settings/transaction-type"));
const StudentStatus = lazy(() => import("pages/settings/student-status"));
const PaymentMethod = lazy(() => import("pages/settings/payment-method"));
const AbonimentList = lazy(() => import("pages/settings/aboniment/list"));
const AbonimentInfo = lazy(() => import("pages/settings/aboniment/info"));
const Holiday = lazy(() => import("pages/settings/holiday"));
const AutoSms = lazy(() => import("pages/settings/auto-sms"));

const FinanceSettings = lazy(() => import("pages/settings/finance-settings"));
const GeneralSettings = lazy(() => import("pages/settings/general-settings"));
const IntSettings = lazy(() => import("pages/settings/integrations-settings"));


export const privateRoutes: (RouteObject & { role: keyof IRoles_ })[] = [
  {
    element: <Navigate to="/home" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/home" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <BLACK_PAGE />,
    path: "home",
    role: "_id",
  },

  // ? class routes
  {
    element: <Class />,
    path: "/class/*",
    role: "getClasses",
  },
  {
    element: <Group />,
    path: "group/*",
    role: "getClasses", // must change
  },
  {
    element: <Subjects />,
    path: "/subjects/*",
    role: "getSubjects",
  },
  {
    element: <Rooms />,
    path: "/rooms",
    role: "getRooms",
  },
  {
    element: <Students />,
    path: "/students/*",
    role: "getStudents",
  },
  {
    element: <ArchiveStudents />,
    path: "/archive-students/*",
    role: "getStudents",
  },
  {
    element: <Parents />,
    path: "/parents/*",
    role: "getParents",
  },

  // ? dashboard route
  {
    element: <Dashboard />,
    path: "dashboard",
    role: "_id",
  },

  // ? schedule route
  {
    element: <Schedule />,
    path: "schedule",
    role: "getLessons",
  },

  // ? task route
  {
    element: <Task />,
    path: "/task/*",
    role: "_id", //! must change
  },

  // ? journal route
  {
    element: <Journal />,
    path: "/journal/*",
    role: "getClasses",
  },

  // ? chat route
  {
    element: <Chat />,
    path: "/chat/*",
    role: "_id", //!must change
  },

  // ? monthly-assessment route
  {
    element: <MonthlyAssessment />,
    path: "/monthly-assessment/*",
    role: "getSeasonalMarks",
  },
  {
    element: <SeasionalAssestment />,
    path: "/seasional-assessment",
    role: "getSeasonalMarks",
  },

  // ? analitics route
  {
    element: <Appropriation />,
    path: "/analitics/*",
    role: "_id", // ! must change
  },
  {
    element: <TeacherWorkReport />,
    path: "/analitics/teacher-work-reports/*",
    role: "getJournalActivity",
  },
  {
    element: <TeacherSeasionalAssessment />, 
    path: "/analitics/teacher-seasional-assessment/*",
    role: "getTeacherWorkReport",
  },

  // ? finance route
  {
    element: <Cash />,
    path: "/cash/*",
    role: "cashboxGet",
  },

  {
    element: <Salary />,
    path: "/salary/*",
    role: "_id",
  },
  {
    element: <MonthlySalary />,
    path: "/salary/monthly-salary",
    role: "_id",
  },

  {
    element: <FinancialReports />,
    path: "/financial-reports",
    role: "_id",
  },

  // ? managment route
  // {
  //   element: <Managment />,
  //   path: "/managment",
  //   role: "_id",
  // },
  {
    element: <Branch />,
    path: "/branch",
    role: "getBranch",
  },
  {
    element: <Roles />,
    path: "/roles",
    role: "getRoles",
  },
  {
    element: <Employees />,
    path: "/employees/*",
    role: "getEmployees",
  },

  // ? settings routes
  {
    element: <FinanceSettings />,
    path: "/finance-settings",
    role: "FinanceSettings",
  },
  {
    element: <IntSettings />,
    path: "/integrations-settings",
    role: "integrations",
  },
  {
    element: <GeneralSettings />,
    path: "/general-settings",
    role: "generalSettings",
  },
  {
    element: <AbonimentInfo />,
    path: "aboniment/:id",
    role: "subscriptionGet",
  },
];

export const teacherRoutes: (RouteObject & { role: keyof IRoles_ })[] = [
  {
    element: <Navigate to="/schedule" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/schedule" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <BLACK_PAGE />,
    path: "home",
    role: "_id",
  },

  // ? time-table route
  {
    element: <Schedule />,
    path: "schedule",
    role: "getLessons",
  },

  // ? journal route
  {
    element: <Journal />,
    path: "/journal/*",
    role: "getClasses",
  },

  // ? chat route
  {
    element: <Chat />,
    path: "/chat/*",
    role: "_id", //!must change
  },
  // ? monthly-assessment route
  {
    element: <MonthlyAssessment />,
    path: "/monthly-assessment/*",
    role: "getSeasonalMarks",
  },
  {
    element: <SeasonalAppropriation />,
    path: "/seasonal-appropriation/*",
    role: "_id",
  },
];
